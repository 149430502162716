import { PERMISSION_ACTION, PERMISSION_SUBJECT } from '@finance-ops/types';
import { Badge, Box, MenuItem, useTheme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetURLParams } from '../@core/utils';
import CanWrapper from '../layouts/components/acl/CanWrapper';
import { RootState } from '../store';
import { useGetClientsQuery } from '../store/api/clientsApi';
import { setSelectedClientId } from '../store/client';

const ClientList = ({ getClientUnreadCount }: { getClientUnreadCount?: (clientId: string) => number }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { data: clients } = useGetClientsQuery();
  const selectedClientId = useSelector((state: RootState) => state.client.selectedClientId);
  const defaultValue = { label: 'All', value: null };
  const [selectedClient, setSelectedClient] = useState<{ [key: string]: any }>(defaultValue);
  const [inputValue, setInputValue] = useState('');

  const ItemStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&.MuiMenuItem-root': {
      borderRadius: 0,
      paddingTop: '2px',
      paddingBottom: '2px',
      fontWeight: '500',
    },
    '&.MuiMenuItem-root:hover': {
      borderRadius: '18px',
      backgroundColor: theme.palette.primary.light,
    },
  };

  const handleNewClient = (newValue: { [key: string]: any }) => {
    setSelectedClient(newValue);
    dispatch(setSelectedClientId(newValue.value));
    resetURLParams(window);
  };

  useEffect(() => {
    if (selectedClientId && clients && clients.length > 0) {
      const selectedClient = clients.find(client => client.id === selectedClientId);
      if (selectedClient) {
        setSelectedClient({ label: selectedClient.name, value: selectedClient.id });
      }
    }
  }, [selectedClientId, clients]);

  return (
    <CanWrapper permission={{ actions: [PERMISSION_ACTION.READ], subject: PERMISSION_SUBJECT.SELECT_CLIENT_ID }}>
      <Autocomplete
        options={[
          defaultValue,
          ...(clients?.filter(client => client.isActive).map(client => ({ label: client.name, value: client.id })) ||
            []),
        ]}
        getOptionLabel={option => option.label}
        clearIcon={false}
        renderInput={params => {
          const unreadCount = (getClientUnreadCount && getClientUnreadCount(selectedClient.value)) || null;
          return (
            <TextField
              className='client-list-text-field'
              variant='outlined'
              sx={{
                width: { xs: '100%', sm: '200px' },
              }}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {unreadCount ? (
                      <Badge
                        badgeContent={unreadCount}
                        color='error'
                        sx={{ ml: '10px', fontSize: '12px', fontWeight: 700 }}
                      />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          );
        }}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderOption={(props, item) => {
          const unreadCount = (getClientUnreadCount && getClientUnreadCount(item.value)) || null;
          return (
            <MenuItem
              key={item.value}
              sx={{
                ...ItemStyle,
                ...(selectedClient === item.key && {
                  borderRadius: '18px !important',
                  backgroundColor: theme.palette.primary.light,
                  color: theme.palette.primary.main,
                }),
              }}
              onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) =>
                props.onClick &&
                props.onClick({
                  ...event,
                  target: item,
                } as any)
              }
              onMouseMove={props.onMouseMove}
              onTouchStart={props.onTouchStart}
            >
              <Box sx={{ color: 'black' }}>{item.label}</Box>
              <Box
                sx={{
                  minWidth: '30px',
                  position: 'relative',
                  top: '-1px',
                  '& .MuiBadge-badge': {
                    background: theme.palette.error.main,
                    color: theme.palette.common.white,
                    right: '-20px',
                    minWidth: '1rem',
                    minHeight: '1rem',
                  },
                }}
              >
                {unreadCount ? <Badge badgeContent={unreadCount} /> : null}
              </Box>
            </MenuItem>
          );
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={(event: React.SyntheticEvent<Element, Event>, val) => {
          if (event.type !== 'change') {
            // eslint-disable-next-line no-prototype-builtins
            if (event.target.hasOwnProperty('value')) {
              handleNewClient(event.target);
            } else {
              handleNewClient(defaultValue);
            }
          }
        }}
        inputValue={inputValue}
        value={selectedClient}
        sx={{
          ml: 4,
          '.MuiInputBase-root': {
            py: 0,
          },
        }}
      />
    </CanWrapper>
  );
};

export default ClientList;
